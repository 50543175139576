/* input type number arrow hide code */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none; margin: 0;
}

html {
    scroll-behavior: smooth;
}

.Flex {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

/* Search Field */
.search-field {
    outline: none;
    border: none;
    padding: 10px;
    background-color: transparent;
}
/* Search Field */

:root {
    --blue-color: #0080ff;
}

@media screen and (min-width: 450px) {
    
    /* Scroll Bar CSS */
    ::-webkit-scrollbar {
        width: 10px;
    }
    
    ::-webkit-scrollbar-track {
        background-color: rgb(255, 255, 255);
    }
    
    ::-webkit-scrollbar-thumb {
        background: #a3b6c9;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background: #75818d;
    }
    /* Scroll Bar CSS */
   
  }